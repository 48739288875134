import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { capitalizeWords } from "../../common/utils";
import { Backdrop } from "../../components/Backdrop";
import Button from "../../components/Button/Button";
import FindLockerContainer from "../../components/FindLockerContainer/FindLockerContainer";
import GoogleMaps from "../../components/GoogleMaps";
import MapListHolder from "../../components/MapListHolder";
import { Container, ContentContainer } from "../../components/WebLayoutComponents";
import { MobileBreakpoint } from "../../config/constants";
import Api from "../../services/Api";
import { default as Chevron, default as DropdownChevron } from "../../static/img/chevron-down-arrow.png";
import NavigationArrow from "../../static/img/Icon-Navigation-Arrow.svg";

const HeaderContainer = styled(ContentContainer)`
  .header {
    font-size: 54px;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .header {
      font-size: 46px;
    }
  }
`;

const OrdersContent = styled.div`
  max-width: 1237px;
  margin: 0 auto;
  width: 100%;
`;

const OrdersContainer = styled.div`
  display: flex;
  padding: 20px 0;
  margin: 0 60px;

  .big-bold-header {
    font-weight: bold;
    font-size: 30px;
  }

  .gray-info-text {
    font-size: 24px;
    color: #565959;
  }

  &.medium-padding {
    padding: 30px 0;
  }

  &.space-between {
    justify-content: space-between;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    padding: 20px;
    border: none;
    margin: 0;

    &.no-padding {
      padding: 0;
    }

    &.medium-padding {
      padding: 30px 20px;
    }

    &.mobile-column {
      flex-direction: column;
    }

    .big-bold-header {
      margin-bottom: 20px;
    }
  }
`;

const OrderDropdownContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  width: fit-content;
  height: 65px;
  padding: 20px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    width: 100%;

    span {
      text-align: center;
      width: 100%;
    }
  }
`;

const OrderItemContainer = styled.div``;

const OrderProductContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 1rem;

  .product-image {
    max-width: 205px;
    max-height: 206px;
    border-radius: 16px;
  }

  .product-title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 1rem;
  }

  .product-subtitle {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .continue-button {
    max-width: 230px;
    margin-top: 20px;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    margin-top: 30px;

    .product-image {
      max-width: 78px;
    }

    .product-title {
      font-size: 26px;
      margin-left: 20px;
    }

    .product-subtitle {
      font-size: 18px;
      margin-top: 10px;
    }

    .continue-button {
      max-width: 1200px;
      width: 100%;
    }
  }
`;

const OrderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  margin-top: 10px;
  margin-right: 30px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const ReturnSectionContainer = styled(ContentContainer)`
  background-color: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 10;
  overflow: hidden;
  margin-bottom: 20px;

  &.horizontal {
    display: flex;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    border-right: none;
    border-left: none;

    .top-section {
      margin-top: 30px;
      border-top: 1px solid #cdcdcd;
    }
  }
`;

const ReturnShippingMethod = () => {
  const api = new Api();
  api.setup();

  const navigate = useNavigate();

  const [returnItem, setReturnItem] = useState();
  const [findLockerModalOpen, setFindLockerModalOpen] = useState(false);
  const radiusInput = 20;
  const [json, setJson] = useState();
  const [mapCenter, setMapCenter] = useState({
    lat: 39.8283,
    lng: -98.5795,
  });
  const [centerChange, setCenterChange] = useState(false);
  const [selectedLocker, setSelectedLocker] = useState();
  const [mapZoom, setMapZoom] = useState(4);

  const changeMapCenter = useCallback(() => {
    if (json?.data.lockers !== null) {
      const Lati = parseFloat(Number(json?.data.lockers[0].locationLatitude));
      const Long = parseFloat(Number(json?.data.lockers[0].locationLongitude));
      setMapCenter({ lat: Lati, lng: Long });
      setMapZoom(10);
    }
    setCenterChange(false);
  }, [json]);

  useEffect(() => {
    if (centerChange && json) {
      changeMapCenter();
    }
  }, [json, centerChange, changeMapCenter]);

  const mapCenterChange = () => {
    setCenterChange(true);
  };

  const handleContinueButton = () => {
    navigate("/return/confirmation");
  };

  const selectLockerLocation = locker => {
    setSelectedLocker(locker);
    setFindLockerModalOpen(false);
  };

  const lockerListSet = data => {
    setJson(data);
  };

  useEffect(() => {
    if (!sessionStorage.getItem("return-item")) navigate("/orders");
    else setReturnItem(JSON.parse(sessionStorage.getItem("return-item")));
  }, [navigate]);

  return (
    <Container className="gray-white-background">
      {findLockerModalOpen && (
        <>
          <Backdrop active={findLockerModalOpen} onClick={() => setFindLockerModalOpen(false)} />
          <FindLockerContainer>
            <MapListHolder
              radiusInput={radiusInput}
              exit={() => setFindLockerModalOpen(false)}
              lockerList={json}
              handleLockerSelect={selectLockerLocation}
              setLockerListRaiseFunction={lockerListSet}
              centerChangeRaiseFunction={mapCenterChange}
            />
            <GoogleMaps lockerList={json} mobile mapCenter={mapCenter} zoom={mapZoom} />
          </FindLockerContainer>
        </>
      )}
      <OrdersContent>
        <HeaderContainer className="horizontal full">
          <h1 className="header">Item to return</h1>
        </HeaderContainer>
        <ContentContainer className="horizontal full" style={{ justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              gap: 10,
              backgroundColor: "#F3F3F3",
              borderRadius: 10,
              padding: 5,
            }}
          >
            <Button variant="contained" color="secondary" className="mobile-full-width">
              Orders
            </Button>
            <Button variant="text" className="desktop-only">
              Not Yet Shipped
            </Button>
            <Button variant="text" className="desktop-only">
              Cancelled Orders
            </Button>
          </div>
          <OrderDropdownContainer>
            <span style={{ fontWeight: "600" }}>Past 3 Months</span>
            <img
              src={DropdownChevron}
              alt="dropdown arrow"
              style={{ width: "18px", height: "11px", margin: "auto 0 auto 10px" }}
            />
          </OrderDropdownContainer>
        </ContentContainer>
        <ContentContainer className="full" style={{ marginTop: "30px" }}>
          <ReturnSectionContainer disableGutters className="full">
            <OrdersContainer className="medium-padding space-between mobile-column top-section">
              <span className="big-bold-header">Why are you returning this?</span>
              <span className="gray-info-text">Wrong item was sent</span>
            </OrdersContainer>
          </ReturnSectionContainer>
          <ReturnSectionContainer disableGutters className="full">
            <OrdersContainer className="medium-padding space-between mobile-column">
              <span className="big-bold-header">How can we make it right?</span>
              <span className="gray-info-text">Refund to your Authentik Adventures account balance</span>
            </OrdersContainer>
          </ReturnSectionContainer>
          <ReturnSectionContainer disableGutters className="full">
            <div style={{ backgroundColor: "#FFFFFF" }}>
              <OrdersContainer className="no-padding">
                <OrderItemContainer key={returnItem?.productInfo.id}>
                  <h5 className="big-bold-header desktop-only">How would you like to return your items</h5>
                  <h5 className="big-bold-header mobile-only">Delivered {returnItem?.deliveredDate}</h5>
                  {selectedLocker && (
                    <>
                      <span style={{ color: "#565959", fontSize: "24px", marginTop: "10px", marginBottom: "0" }}>
                        {capitalizeWords(selectedLocker?.locationAddress1) +
                          ", " +
                          capitalizeWords(selectedLocker?.locationCity) +
                          ", " +
                          selectedLocker?.locationState +
                          " " +
                          capitalizeWords(selectedLocker?.locationZIP5)}
                      </span>
                      <span
                        style={{ fontSize: "24px", marginLeft: "20px", cursor: "pointer" }}
                        className="normal-link-color"
                        onClick={() => setFindLockerModalOpen(true)}
                        onKeyDown={() => setFindLockerModalOpen(true)}
                      >
                        Change Address{" "}
                        <img
                          src={Chevron}
                          alt="chevron"
                          style={{ width: "18px", height: "11px", justifyContent: "center" }}
                        />
                      </span>
                    </>
                  )}
                  <OrderProductContainer>
                    <img
                      src={returnItem?.productInfo.source}
                      alt={returnItem?.productInfo.alt}
                      className="product-image desktop-only"
                    />
                    <OrderDetailContainer>
                      <div style={{ display: "flex" }}>
                        <img
                          src={returnItem?.productInfo.source}
                          alt={returnItem?.productInfo.alt}
                          className="product-image mobile-only"
                        />
                        <span className="product-title">{returnItem?.productInfo.title}</span>
                      </div>
                      <span className="product-subtitle">
                        Return or replace items: Eligible through {returnItem?.returnByDate}
                      </span>
                      <div className="desktop-only">
                        <span style={{ fontWeight: "bold", fontSize: "22px" }}>Drop off at a USPS Smart Locker</span>
                      </div>
                      <span style={{ fontSize: "22px", marginTop: "10px", color: "black" }} className="desktop-only">
                        Locate a USPS Post Office with a{" "}
                        <button
                          className="hyperlink-button"
                          style={{ fontSize: "22px" }}
                          onClick={() => setFindLockerModalOpen(true)}
                        >
                          drop-off site
                        </button>{" "}
                        near you.
                      </span>
                      <Button
                        className="continue-button desktop-only"
                        onClick={handleContinueButton}
                        disabled={!selectedLocker}
                      >
                        Continue
                      </Button>
                    </OrderDetailContainer>
                  </OrderProductContainer>
                </OrderItemContainer>
              </OrdersContainer>
            </div>
          </ReturnSectionContainer>
          <ReturnSectionContainer
            disableGutters
            className="mobile-only-flex"
            onClick={() => setFindLockerModalOpen(true)}
          >
            <OrdersContainer className="mobile-column" style={{ padding: "20px 0" }}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", fontSize: "22px" }}>Drop off at a USPS Smart Locker</span>
              </div>
              <span style={{ fontSize: "22px", marginTop: "10px", color: "black" }}>
                Locate a USPS Post Office with a{" "}
                <button className="hyperlink-button" style={{ fontSize: "22px" }}>
                  drop-off site
                </button>{" "}
                near you.
              </span>
            </OrdersContainer>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "auto" }}>
              <img src={NavigationArrow} alt="chevron" style={{ width: "20px", height: "32px" }} />
            </div>
          </ReturnSectionContainer>
          <Button
            className="continue-button mobile-only mobile-full-width"
            onClick={handleContinueButton}
            disabled={!selectedLocker}
          >
            Continue
          </Button>
        </ContentContainer>
      </OrdersContent>
    </Container>
  );
};

export default ReturnShippingMethod;
