import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  background-color: #e7f6f8;
  border-left: 15px solid #3573b1;
  display: flex;
  margin-top: 20px;
`;

const UspsLockerPopUpWrapperProps = {
  children: PropTypes.node,
};

const UspsLockerPopUpWrapper = props => {
  return <Wrapper>{props.children}</Wrapper>;
};

UspsLockerPopUpWrapper.propTypes = UspsLockerPopUpWrapperProps;

export default UspsLockerPopUpWrapper;
